<template>
    <div style="padding: 0 15.625vw;">
      <div class="xxjj">
      <div class="page-herader">
        <span>招生就业</span>
      </div>
        <div class="lmy-center">
          <div v-if="content.primersTitle" style="padding: 0 0 0.52083vw 0;font-size: 1.45833vw;color: #333333;font-weight: 500;">{{ content.primersTitle }}</div>
          <div style="font-size: 1.77083vw;text-align: center;">{{ content.title }}</div>
          <div v-if="content.subTitle" style="padding: 1.04167vw 1.04167vw 0.52083vw 1.04167vw;font-size: 1.45833vw;color: #333333;text-align: right;font-weight: 500;">{{ content.subTitle }}</div>
          <div class="content_source" style="padding: 0.83333vw 0 0 3.64583vw;font-size: 0.83333vw;color: #6c6a6a;">
            <el-divider direction="vertical"></el-divider>
            <span v-if="content.mapProperties && content.mapProperties.wenzi">{{ $t('source_2') }} ：{{ content.mapProperties && content.mapProperties.wenzi }}</span>
            <span v-if="content.mapProperties && content.mapProperties.sheying">{{ $t('source_3') }} ：{{ content.mapProperties &&   content.mapProperties.sheying }}</span>
            <span v-if="content.author">{{ $t('source_4') }} ：{{ content.author }}</span>
            <span v-if="content.origin">{{ $t('source_1') }} ：{{ content.origin }}</span>
            <span v-if="content.publishTime">{{$moment(content.publishTime).format('YYYY-MM-DD')}}</span>
          </div>
          <div class="content 10" v-html="content.content"></div>
        </div>
      </div>
    </div>
  </template>

  <script>
  export default {
    data() {
      return {
        content: "",
        informationArray: []
      };
    },
    created() {
      this.getStory();
    },
    methods: {
      async getStory() {
        const params = {
          pageNumber: 0,
          pageSize: 2,
          columnIds: this.$columnIdFile.xxgkxxjj
        }
        const res = await this.API.basic.getHomeRotographList(params);
        if(res.data.totalElements > 0) {
          const res1 = await this.API.basic.getManuscriptDetails(res.data.content[0].id)
          this.content = res1.data;
        }
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .xxjj{
    background: #fff;
  }
  img{
    max-width: 100%;
  }
  .content_source{
    span{
      padding-right: 16px;
    }
  }
  .lmy-center {
    margin-top: 18px;
    margin-bottom: 36px;
    .content {
      padding: 0 70px;
      ::v-deep a{
        color: #002bfd !important;
      }
      ::v-deep p {
        line-height: 50px;
        color: #333;
        font-family: 微软雅黑,Microsoft YaHei;
        font-size: 16px;
      }
      ::v-deep img {
        max-width: 100%;
        margin: auto !important;
      }
    }
  }
  </style>
